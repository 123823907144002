import { downloadFile } from "../../../web/utils/download";
import { logger } from "../../core/logging/logger";
import type { ConnectedServiceDomainApiService } from "../../core/net/connected-service-domain-api-service";

export class IdentityService {
  public constructor(readonly apiService: ConnectedServiceDomainApiService) {}

  public async fetchClientRole() {
    try {
      const response = await this.apiService.instance.get("/identities?role=CLIENT");
      return response.data;
    } catch (e) {
      logger.debug("IdentityService", "Failed to fetch client role", e);
      throw e;
    }
  }

  public async fetchKycLabels(profileId: string) {
    try {
      const response = await this.apiService.instance.get("/additional-data-requirements", {
        params: {
          legalType: "NATURAL_PERSON",
          profileId,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("IdentityService", "Failed to fetch kyc labels", e);
      throw e;
    }
  }

  public async fetchKycDocuments(clientId: string) {
    try {
      const response = await this.apiService.instance.get(`/identities/${clientId}/documents`);
      return response.data;
    } catch (e) {
      logger.debug("IdentityService", "Failed to fetch kyc documents", e);
      throw e;
    }
  }

  public async downloadKycDocument(id: string) {
    try {
      const response = await this.apiService.instance.get(`/documents/${id}/content`, {
        responseType: "arraybuffer",
      });

      const contentType = response.headers["content-type"] as string;
      const contentFileType = contentType && contentType.split(";")[0];

      downloadFile(`${id}_document.png`, response.data, contentFileType ?? "image/png");

      return `${id}_details.png`;
    } catch (e) {
      logger.debug("IdentityService", "Failed to download kyc document", e);
      throw e;
    }
  }
}
