import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { AccountBlockedScreen } from "../../ui/screen/account/account-blocked-screen";
import AccountTransactionsScreen from "../../ui/screen/account-transactions/account-transactions-screen";
import AllAccountsScreen from "../../ui/screen/account-transactions/all-accounts-screen";
import { ContactScreen } from "../../ui/screen/bank-detail/contact-screen";
import BillIssuersScreen from "../../ui/screen/bill/bill-issuers-screen";
import { BillReferencesScreen } from "../../ui/screen/bill/bill-references-screen";
import { AllCardsScreen } from "../../ui/screen/cards/all-cards-screen";
import { CardOutstandingsScreen } from "../../ui/screen/cards/card-outstandings";
import { ConnectScreen } from "../../ui/screen/connect/connect-screen";
import { OnboardingFileScreen } from "../../ui/screen/enrollment/onboarding/onboarding-file-screen";
import { OnboardingFileSubmittedScreen } from "../../ui/screen/enrollment/onboarding/onboarding-file-submitted-screen";
import OnboardingRedirectScreen from "../../ui/screen/enrollment/onboarding/onboarding-redirect-screen";
import { OnboardingScreen } from "../../ui/screen/enrollment/onboarding/onboarding-screen";
import { RegisterScreen } from "../../ui/screen/enrollment/register/register-screen";
import { UbbleCheckScreen } from "../../ui/screen/enrollment/ubble/ubble-check-screen";
import { ErrorScreen } from "../../ui/screen/error/error-screen";
import JustifyTransactionScreen from "../../ui/screen/justification/justify-transaction-screen";
import { ExternalLoansScreen } from "../../ui/screen/loan/external-loans";
import LoginScreen from "../../ui/screen/login/login-screen";
import { OAuthLoadingScreen } from "../../ui/screen/login/oauth-loading-screen";
import { RechargeScreen } from "../../ui/screen/recharge/recharge-screen";
import { RecipientsScreen } from "../../ui/screen/recipient/recipients-screen";
import { CreateSavingScreen } from "../../ui/screen/saving/create-saving-screen";
import { MyProfileScreen } from "../../ui/screen/settings/my-profile-screen";
import { MySettingsScreen } from "../../ui/screen/settings/my-settings-screen";
import { TransactionCodesScreen } from "../../ui/screen/transaction-codes/transaction-codes-screen";
import { CashTransferScreen } from "../../ui/screen/transfer/cash-transfer-screen";
import { SimpleTransferScreen } from "../../ui/screen/transfer/simple-transfer-screen";
import { TransferScreen } from "../../ui/screen/transfer/transfer-screen";
import UpcomingTransferScreen from "../../ui/screen/transfer/upcoming-transfers-screen";
import { Path } from "./path";
import { AuthNavRoute } from "./routes/auth-nav-route";
import { HomeRoute } from "./routes/home-route";
import { OAuthRedirectRoute } from "./routes/oauth-redirect-route";

export function AppRouter() {
  return (
    <Switch>
      <HomeRoute exact path={Path.Root} component={LoginScreen} />
      <OAuthRedirectRoute path={Path.OAuthRedirect} component={OAuthLoadingScreen} />
      <Route path={Path.ErrorScreen} component={ErrorScreen} />
      <Route path={Path.UbbleRedirect} exact={false} component={RegisterScreen} />
      <Route path={Path.Register} component={RegisterScreen} />
      <Route path={Path.Onboarding} component={OnboardingScreen} />
      <Route path={Path.OnboardingFileSubmitted} component={OnboardingFileSubmittedScreen} />
      <Route path={Path.UbbleRedirectNewOnboarding} component={OnboardingRedirectScreen} />
      <Route path={Path.Connect} component={ConnectScreen} />
      <Route path={Path.UbbleCheck} component={UbbleCheckScreen} />
      <AuthNavRoute path={Path.OnboardingFile} component={OnboardingFileScreen} />
      <AuthNavRoute path={Path.AccountBlocked} component={() => <AccountBlockedScreen />} />
      <AuthNavRoute
        path={Path.AccountBlockedAfterPincodeError}
        component={() => <AccountBlockedScreen isThreePincodeAttemptsError />}
      />
      <AuthNavRoute path={Path.AccountTransactions} component={AccountTransactionsScreen} />
      <AuthNavRoute path={Path.AllAccounts} component={AllAccountsScreen} />
      <AuthNavRoute path={Path.Transfer} component={TransferScreen} />
      <AuthNavRoute path={Path.SimpleTransfer} component={SimpleTransferScreen} />
      <AuthNavRoute path={Path.CashTransfers} component={CashTransferScreen} />
      <AuthNavRoute path={Path.UpcomingTransfers} component={UpcomingTransferScreen} />
      <AuthNavRoute path={Path.BillIssuers} component={BillIssuersScreen} />
      <AuthNavRoute path={Path.References} component={BillReferencesScreen} />
      <AuthNavRoute path={Path.Cards} component={AllCardsScreen} />
      <AuthNavRoute path={Path.AllCards} component={AllCardsScreen} />
      <AuthNavRoute path={Path.CardOutstandings} component={CardOutstandingsScreen} />
      <AuthNavRoute path={Path.BankContact} component={ContactScreen} />
      <AuthNavRoute path={Path.Profile} component={MyProfileScreen} />
      <AuthNavRoute path={Path.Settings} component={MySettingsScreen} />
      <AuthNavRoute path={Path.Recharge} component={RechargeScreen} />
      <AuthNavRoute path={Path.Loans} component={ExternalLoansScreen} />
      <AuthNavRoute path={Path.PspRedirect} exact={false} component={RechargeScreen} />
      <AuthNavRoute path={Path.TransactionCodes} component={TransactionCodesScreen} />
      <AuthNavRoute path={Path.JustifyTransaction} component={JustifyTransactionScreen} />
      <AuthNavRoute path={Path.Recipients} component={RecipientsScreen} />
      <AuthNavRoute path={Path.CreateSavingAccount} component={CreateSavingScreen} />
      <Redirect path={"*"} to={Path.Root} />
    </Switch>
  );
}
