import type { CurrencyIdentifier } from "../currency/currency";
import { Currencies } from "../currency/currency";

export class Amount {
  public value: number;
  public originalInput?: string;

  public constructor(
    value: number | null,
    public currency: CurrencyIdentifier,
    originalInput?: string,
  ) {
    const currencyData = Currencies[currency];
    const decimalPlaces = currencyData ? currencyData.decimal_number : 2;

    // Store value as an integer of the smallest unit (e.g., cents)
    this.value = Math.round((value ?? 0) * Math.pow(10, decimalPlaces));

    // Preserve the exact original input if provided
    this.originalInput = originalInput?.trim();
  }

  // This class is deserialized directly from JSON, please don't put functions in there
  // it's a class rather than an interface to force initialization via constructor
}

export function addAmounts(...amounts: Amount[]) {
  const currency = amounts[0].currency;
  const currencyData = Currencies[currency];
  return new Amount(
    amounts.reduce((total, amount) => {
      if (currency != amount.currency) {
        throw new Error("trying to add amounts with different currencies");
      }
      return total + amount.value / Math.pow(10, currencyData.decimal_number);
    }, 0),
    currency,
  );
}

export function diffAmount(firstAmount: Amount, secondAmount: Amount) {
  const diffVal =
    (Math.abs(firstAmount.value) - Math.abs(secondAmount.value)) /
    Math.pow(10, Currencies[firstAmount.currency].decimal_number);
  return new Amount(diffVal, firstAmount.currency);
}

export function amountValueIsLower(firstAmount: Amount, secondAmount: Amount) {
  return Math.abs(firstAmount.value) < Math.abs(secondAmount.value);
}

export function absoluteAmount(amount: Amount) {
  const absoluteVal = Math.abs(amount.value) / Math.pow(10, Currencies[amount.currency]?.decimal_number);
  return new Amount(absoluteVal, amount.currency);
}

export function getAmountValueString(amount: Amount): string {
  if (amount.originalInput !== undefined) {
    return amount.originalInput.replace(/^0+(?=\d)/, "");
  }

  const decimalNumber = Currencies[amount.currency]?.decimal_number ?? 2;
  const valueString = (amount.value / 10 ** decimalNumber).toFixed(decimalNumber);

  // Return "0" for exact zero values
  if (parseFloat(valueString) === 0) return "0";

  // Remove trailing zeros only if the user did not explicitly enter them
  return valueString.replace(/(?:\.0+|(\.\d*?[1-9])0+)$/, "$1");
}

export function amountValueForCurrency(value: number, currency: string) {
  const currencyData = Currencies[currency];
  if (!currencyData) {
    throw new Error(`Currency ${currency} not found in Currencies object`);
  }
  return value / Math.pow(10, currencyData.decimal_number);
}

function convertValue(value: number, oldDecimals: number, newDecimals: number) {
  const decimalValue = value / Math.pow(10, oldDecimals);
  const integerPart = Math.trunc(decimalValue);
  const decimalPart = Math.trunc((decimalValue - integerPart) * Math.pow(10, newDecimals) + 0.00001);
  return integerPart + decimalPart / Math.pow(10, newDecimals);
}

export function switchCurrency(amount: Amount, currency: CurrencyIdentifier) {
  const convertedValue = convertValue(
    amount.value,
    Currencies[amount.currency].decimal_number,
    Currencies[currency].decimal_number,
  );
  return new Amount(convertedValue, currency);
}

export const sanitizeAmountValue = (str: string | null, decimals: number) => {
  let safeValue = "";
  if (str) {
    str = str.replace(/,/g, ".");
    // Remove invalid characters and handle leading period
    const sanitizedString = str.replace(/[^0-9.]/g, "").replace(/^\./, "0.");
    const index = sanitizedString.indexOf(".");
    if (index > 0) {
      const lengthMax = Math.min(sanitizedString.length, index + 1 + decimals);
      const decimalPart = sanitizedString.substring(index + 1, lengthMax);
      safeValue = sanitizedString.substring(0, index + 1) + decimalPart;
    } else {
      safeValue = sanitizedString;
    }
  }
  if (parseFloat(safeValue) === 0) {
    return "0";
  }
  return safeValue;
};

// Regex: validAmountRegex
// This regex is used to validate amounts with the following rules:
// - Allows an optional decimal point, with at most two digits after it (e.g., 12.34, 0.99, .5, 0.)
// - Does not allow consecutive commas (e.g., "12,,34" is invalid)
// - Does not allow consecutive periods (e.g., "12..34" is invalid)
// - Accepts numbers like "0." or ".5"
// - Only allows digits (0-9) with optional decimal point and up to two digits after it
// - Examples of valid formats: "0", "12.34", "0.99", ".5", "5.", "5.00", "123", "0.5", "0,5"
// - Examples of invalid formats: "12..34", "12,,34", "12.345"
export const validAmountRegex = /^(?!.*[.,]{2})\d*(?:[.,]\d{0,2})?$/;

export function amountsAreEqual(amount1: Amount, amount2: Amount): boolean {
  return amount1.value === amount2.value && amount1.currency === amount2.currency;
}
