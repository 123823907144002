import React, { useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import {
  authenticationManager,
  clientManager,
  configurationManager,
  featuresManager,
} from "../../../../shared/core/service/services";
import { OnboardingStatus } from "../../../../shared/domains/onboarding/onboarding";
import { useOnboardingFile } from "../../../../shared/domains/onboarding/use-onboarding";
import { useObservable } from "../../../../shared/utils/observable";
import { Path } from "../../../core/routing/path";
import { useClient } from "../../../domain/authentication/use-client";
import { useRTL } from "../../../domain/language/use-rtl";
import { StaticAssets } from "../../styles/custom-assets";
import { shadows } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { BottomNav } from "./bottom-nav";
import { sideBarColors } from "./component/colors";
import type { NavigationSubItem } from "./component/sub-menu";
import { SubMenu } from "./component/sub-menu";
import { NavButton } from "./nav-button";
import NavToggleButton from "./nav-toggle-button.tsx";
import type { NavType } from "./nav-type";

interface NavigationItem {
  path: Path;
  type: NavType;
  subItems?: NavigationSubItem[];
  isEnabled: boolean;
}

function Menu(props: { onItemClick?: () => void }) {
  const { onItemClick } = props;
  const { pathname } = useLocation();
  const features = useObservable(featuresManager.features);
  const { onboardingFile } = useOnboardingFile();
  const { client } = useClient();
  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const transfertSubItems: NavigationSubItem[] = useMemo(() => {
    const subItems: NavigationSubItem[] = [];

    if (features.p2pTransfer) {
      subItems.push({ title: "transfertsSubmenu.allTransfer", path: Path.Transfer });
    }
    if (configurationManager.isSimplePayment() && features.p2pSimpleTransfer) {
      subItems.push({ title: "transfertsSubmenu.simpleTransfer", path: Path.SimpleTransfer });
    }
    if (features.p2pCashTransfer) {
      subItems.push({ title: "transfertsSubmenu.cashTransfer", path: Path.CashTransfers });
    }
    if (features.paymentStandingOrderView && features.customerInstructionInitiation) {
      subItems.push({ title: "transfertsSubmenu.upcomingTransfers", path: Path.UpcomingTransfers });
    }
    if (features.bankToWallet || features.walletRechargeByCard) {
      subItems.push({ title: "transfertsSubmenu.recharge", path: Path.Recharge });
    }
    if (features.transactionCode) {
      subItems.push({ title: "transfertsSubmenu.transactionCode", path: Path.TransactionCodes });
    }
    if (features.externalAccountsManagement || features.beneficiariesManagement) {
      subItems.push({ title: "transfertsSubmenu.recipients", path: Path.Recipients });
    }
    return subItems;
  }, [features]);

  const savingsSubItems: NavigationSubItem[] = useMemo(() => {
    const subItems: NavigationSubItem[] = [];

    if (features.savProductRead && features.savContractWrite) {
      subItems.push({ title: "savingsSubmenu.createSavingsAccount", path: Path.CreateSavingAccount });
    }

    return subItems;
  }, [features]);

  const canShowTransferMenu =
    features.p2pTransfer ||
    features.p2pCashTransfer ||
    features.bankToWallet ||
    features.walletRechargeByCard ||
    features.transactionCode ||
    features.externalAccountsManagement ||
    features.beneficiariesManagement;

  const navigationItems: NavigationItem[] = [
    { path: Path.AccountTransactions, type: "account", isEnabled: true },
    { path: Path.TransferMenu, type: "transfers", isEnabled: canShowTransferMenu, subItems: transfertSubItems },
    { path: Path.BillIssuers, type: "services", isEnabled: features.billPayment },
    { path: Path.Cards, type: "cards", isEnabled: features.cards },
    {
      path: Path.Cards,
      type: "savings",
      isEnabled: features.savProductRead && features.savContractWrite,
      subItems: savingsSubItems,
    },
    { path: Path.Loans, type: "loan", isEnabled: features.consultLoans },
  ];

  const { isRTL } = useRTL();
  return (
    <>
      <NavToggleButton isMenuVisible={isMenuVisible} toggleMenu={() => setIsMenuVisible((prev) => !prev)} />
      <MenuContainer role="menu" $isRTL={isRTL} $isVisible={isMenuVisible} data-testid="menu">
        <Logo alt="Skaleet" src={StaticAssets.logo} data-testid="logo" />
        {onboardingFile?.status !== OnboardingStatus.PENDING_VERIFICATION && (
          <>
            {navigationItems.map((item) => {
              const isActive = pathname.startsWith(item.path);
              return item.isEnabled ? (
                item.subItems && item.subItems.length > 0 ? (
                  <SubMenu key={item.type} items={item.subItems} isActive={isActive} onClick={() => onItemClick?.()}>
                    <StyledNavButton linkType={item.type} isActive={isActive} withBackground={false} />
                  </SubMenu>
                ) : (
                  <StyledLink key={item.type} to={item.path} onClick={() => onItemClick?.()}>
                    <StyledNavButton linkType={item.type} isActive={isActive} />
                  </StyledLink>
                )
              ) : null;
            })}
          </>
        )}
        <Spacer />
        <ContactLink to={Path.BankContact} onClick={() => props.onItemClick?.()}>
          <StyledNavButton linkType={"contact"} isActive={pathname.startsWith(Path.BankContact)} />
        </ContactLink>
        <BottomNav
          client={client}
          onDisconnectClick={async () => {
            await authenticationManager.logout();
            await clientManager.clear();
          }}
          onProfileClick={() => props.onItemClick?.()}
        />
      </MenuContainer>
    </>
  );
}

export function Nav() {
  return <Menu />;
}

const MenuContainer = styled.nav<{ $isRTL: boolean; $isVisible: boolean }>`
  flex: 0 0 auto;
  position: fixed;
  z-index: 100;
  top: 10px;
  bottom: 10px;
  left: ${(props) => (props.$isRTL ? "unset" : `${UIConstants.NAV_LEFT_MARGIN_DESKTOP_SMALL}px`)};
  right: ${(props) => (props.$isRTL ? `${UIConstants.NAV_LEFT_MARGIN_DESKTOP_SMALL}px` : "unset")};
  display: flex;
  flex-direction: column;
  padding: 19px 0 0 0;
  border-radius: 11px;
  background-color: ${sideBarColors.background};
  height: auto;
  min-width: ${UIConstants.NAV_WIDTH}px;
  transition: 0.5s;

  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
    padding-top: 80px;
    opacity: ${(props) => (props.$isVisible ? "1" : "0")};
    transform: ${(props) => (props.$isVisible ? "none" : "translateX(-100%)")};
    left: 0;
    bottom: 0;
    border-radius: 0;
  }
  ${shadows.medium};
`;

const Logo = styled.img`
  align-self: center;
  width: auto;
  height: 100px;
  object-fit: fill;
  border-radius: 13px;
  margin-bottom: 24px;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
    margin-bottom: 10px;
    display: none;
  }
`;

const StyledNavButton = styled(NavButton)`
  &:not(:last-of-type) {
    margin-bottom: 2px;
  }
`;

const StyledLink = styled(Link)`
  width: 100%;
  display: flex;
  justify-content: stretch;
  margin-bottom: 2px;
`;

const ContactLink = styled(Link)`
  margin: 16px 0;
`;

const Spacer = styled.div`
  flex: 1 1 auto;
`;
