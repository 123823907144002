import styled from "styled-components";

import { UIConstants } from "../styles/uiConstants";

const PADDING_NOT_MOBILE = 120;
const PADDING_LEFT_DESKTOP_SMALL =
  UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_SMALL +
  UIConstants.NAV_WIDTH +
  UIConstants.NAV_LEFT_MARGIN_DESKTOP_SMALL;
const PADDING_LEFT_DESKTOP_LARGE =
  UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_LARGE +
  UIConstants.NAV_WIDTH +
  UIConstants.NAV_LEFT_MARGIN_DESKTOP_LARGE;

export const ResponsiveContainer = styled.div<{ withNav?: boolean; $isRTL?: boolean }>`
  width: ${UIConstants.PAGE_MAX_WIDTH}px;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 80px;
  padding-left: ${(props) =>
    props.$isRTL ? PADDING_NOT_MOBILE : UIConstants.CONTENT_HORIZONTAL_PADDING_NOT_DESKTOP}px;
  padding-right: ${(props) =>
    props.$isRTL ? PADDING_NOT_MOBILE : UIConstants.CONTENT_HORIZONTAL_PADDING_NOT_DESKTOP}px;

  @media (min-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    padding-top: 60px;
    padding-left: ${(props) =>
      props.$isRTL
        ? UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_SMALL
        : props.withNav
          ? PADDING_LEFT_DESKTOP_SMALL
          : UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_SMALL}px;
    padding-right: ${(props) =>
      props.$isRTL
        ? props.withNav
          ? PADDING_LEFT_DESKTOP_SMALL
          : UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_SMALL
        : UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_SMALL}px;
  }
  @media (min-width: ${UIConstants.DESKTOP_LARGE_BREAKPOINT}px) {
    padding-left: ${(props) =>
      props.$isRTL
        ? UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_LARGE
        : props.withNav
          ? PADDING_LEFT_DESKTOP_LARGE
          : UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_LARGE}px;
    padding-right: ${(props) =>
      props.$isRTL
        ? props.withNav
          ? PADDING_LEFT_DESKTOP_LARGE
          : UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_LARGE
        : UIConstants.CONTENT_HORIZONTAL_PADDING_DESKTOP_LARGE}px;
  }
`;
