import React, { useState } from "react";
import type { ViewProps } from "react-native";
import OutsideClickHandler from "react-outside-click-handler";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

import type { Path } from "../../../../core/routing/path";
import { useRTL } from "../../../../domain/language/use-rtl";
import { SmallChevronIcon } from "../../svg/small-chevron-icon";
import { SubNavButton } from "../nav-button";
import { sideBarColors } from "./colors";

export interface NavigationSubItem {
  title: string;
  path: Path;
}

export interface SubMenuProps extends ViewProps {
  items: NavigationSubItem[];
  isActive: boolean;
  onClick: () => void;
}

export const SubMenu: React.FC<SubMenuProps> = (props) => {
  const { children, items, isActive, onClick } = props;
  const { pathname } = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const { isRTL } = useRTL();

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setIsOpen(false);
      }}
    >
      <SubMenuContainer role="menu">
        <SubItemsContainer>
          <SubItemsRow onClick={() => setIsOpen((prev) => !prev)}>
            {children}
            <ChevronContainer>
              <Chevron
                color={isActive ? sideBarColors.activeItem : sideBarColors.inactiveItem}
                $isOpen={isOpen}
                $isRTL={isRTL}
              />
            </ChevronContainer>
          </SubItemsRow>
        </SubItemsContainer>
        {isOpen && (
          <ExpandedSubMenu>
            <VerticalLine $isRTL={isRTL} />
            <ExpandedItems>
              <SubMenuItems items={items} pathname={pathname} onClick={onClick} />
            </ExpandedItems>
          </ExpandedSubMenu>
        )}
      </SubMenuContainer>
    </OutsideClickHandler>
  );
};

const SubMenuItems = (props) => {
  const { items, pathname, onClick } = props;

  return items.map((subItem: NavigationSubItem) => {
    const isSubItemActive = pathname.startsWith(subItem.path);
    return (
      <SubItemLink key={subItem.title} to={subItem.path} onClick={() => onClick?.()}>
        <StyledSubNavButton name={subItem.title} isActive={isSubItemActive} />
      </SubItemLink>
    );
  });
};

const ChevronContainer = styled.div`
  flex: 0;
  justify-content: center;
  align-items: center;
  margin: 12px;
  padding: 12px;
  :hover {
    cursor: pointer;
  }
`;

const Chevron = styled(SmallChevronIcon)<{ $isOpen: boolean; $isRTL: boolean }>`
  width: 15px;
  height: 15px;
  transform: ${(props) => (props.$isOpen ? (props.$isRTL ? "rotate(270deg)" : "rotate(-90deg)") : "rotate(90deg)")};
  transition: transform 0.3s ease-in;
`;

const ExpandedSubMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const VerticalLine = styled.div<{ $isRTL: boolean }>`
  margin: ${(props) => (props.$isRTL ? "0 40px 0 0" : "0 0 0 40px")};
  min-height: 100%;
  width: 1px;
  background-color: ${sideBarColors.verticalLine};
  z-index: 2;
`;

const ExpandedItems = styled.div`
  display: flex;
  margin-left: -40px;
  flex-direction: column;
`;

const SubMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledSubNavButton = styled(SubNavButton)`
  &:not(:last-of-type) {
    margin-bottom: 2px;
  }
`;

const SubItemLink = styled(Link)``;

const SubItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubItemsRow = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: stretch;
  align-items: center;
`;
