import React from "react";
import styled from "styled-components";

import { StaticAssets } from "../../styles/custom-assets";
import { theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { sideBarColors } from "./component/colors";

interface NavToggleButtonProps {
  isMenuVisible: boolean;
  toggleMenu: () => void;
}

const NavToggleButton: React.FC<NavToggleButtonProps> = ({ isMenuVisible, toggleMenu }) => (
  <NavOnMobileContainer data-testid="nav-mobile-container">
    <LogoOnMobile alt="Skaleet" src={StaticAssets.logo} />
    <ToggleButton
      data-testid="nav-toggle-button"
      aria-pressed={isMenuVisible}
      onClick={toggleMenu}
      className={isMenuVisible ? "active" : ""}
    >
      <Line />
      <Line />
      <Line />
    </ToggleButton>
  </NavOnMobileContainer>
);

export default NavToggleButton;

const NavOnMobileContainer = styled.div`
  display: none;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: center;
    padding: 8px 16px;
    background: ${sideBarColors.background};
    box-shadow: rgba(0, 0, 0, 0.08) 0 0 10px;
  }
`;

const LogoOnMobile = styled.img`
  align-self: center;
  width: auto;
  height: 50px;
  line-height: 50px;
  object-fit: fill;
  border-radius: 13px;
`;

const Line = styled.span`
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  border-radius: 2px;
  transition: all 0.3s ease;
`;

const ToggleButton = styled.button`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: ${theme.mainColor};
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 16px 14px;
  box-sizing: border-box;

  &.active span:nth-child(1) {
    transform: translateY(5px) rotate(45deg);
  }

  &.active span:nth-child(2) {
    opacity: 0;
  }

  &.active span:nth-child(3) {
    transform: translateY(-5px) rotate(-45deg);
  }
`;
